import $ from 'jquery';

document.addEventListener('DOMContentLoaded', initCompanyInfo);
document.addEventListener('pageshow', initCompanyInfo);

function initCompanyInfo() {
  getCompanyInfo();

  $('.community_company_select').on('change', function() {
    
    var that = $(this);

     getCompanyInfo(function(company) {

      if(company && window.company_id != '') {
    
        if(!company.billing_address.address_1 || company.billing_address.address_1.trim() == '') return;
    
        if(!confirm('Do you want to overwrite billing address info with the selected company ('+company.name+')?')) {
            return;
        }
        console.log('new state', company.billing_address.state);
        // window.company_id = company.id;
        
        $('#billing_address_address_1').val(company.billing_address.address_1);
        $('#billing_address_address_2').val('');
        $('#billing_address_city').val(company.billing_address.city);
        $("#billing_address_state").val(company.billing_address.state).trigger('change');
        // $('#billing_address_state').select2('val', company.billing_address.state);
        $('#billing_address_zip').val(company.billing_address.zip);
        // if($('#community_billing_email').val().trim() == '') 
          $('#community_billing_email').val(company.billing_email);
      }
     });
     
  });
 $('#community_manager_id').on('change', function() {
    
    if(window.community_manager && window.community_manager.email && $('#community_billing_email').val() && window.community_manager.email.trim() == $('#community_billing_email').val().trim()) {
       getClientInfo(function(client) {
        if(client) {
      
          if(!client.email || client.email.trim() == '') return;
      
          if(!confirm('Do you want to overwrite the billing email with the email of the selected client ('+client.name+')?')) {
              return;
          }

          window.community_manager.email = client.email;
          $('#community_billing_email').val(client.email);
        }
       });
     }
     
  });
}
var getCompanyInfo = function(callback = null) {
  if($('.community_company_select').val() && $('.community_company_select').val() != '') {
    fetch('/companies/'+$('.community_company_select').val()+'.json')
      .then(response => response.json())
      .then(company => {
        if(!company) return;

         setCompanyInfo(company);
         if(callback) callback(company);
       });
    } else {
      setCompanyInfo();
    }
}
var getClientInfo = function(callback = null) {
  if($('#community_manager_id').val() && $('#community_manager_id').val() != '') {
    fetch('/users/'+$('#community_manager_id').val()+'.json')
      .then(response => response.json())
      .then(user => {
        if(!user) return;

         if(callback) callback(user);
       });
    }
}
var setCompanyInfo = function(company = null) {
var admin_id = $('#community_admin_id').val();
var manager_id = $('#community_manager_id').val();


if($('.community_company_select').val() === '') {
  console.log('defaulting');
  $('#community_admin_id option:not(:selected), #community_manager_id option:not(:selected)').remove();
  if($('#community_admin_id').val() !== '') $('#community_admin_id').prepend('<option value></option>');
  if($('#community_manager_id').val() !== '') $('#community_manager_id').prepend('<option value></option>');


  // $('.community-discount').show();
  if(!window.community_people || !window.community_people.length) {
    window.people.forEach(function(person) {
      window.setTimeout(function() {
        $('#community_admin_id, #community_manager_id').append('<option value="'+person.id+'">'+person.name+'</option>');
      }, 25);
    })

  }
  else {
    window.community_people.forEach(function(person) {
      window.setTimeout(function() {
        $('#community_admin_id, #community_manager_id').append('<option value="'+person.id+'">'+person.name+'</option>');
      }, 25);
    })
  }
  $('#community_admin_id').val(admin_id);
  $('#community_manager_id').val(manager_id);
  return;

}

if(!company) return;
console.log('_company', company);


  console.log('change to company', company);
  if(!company) return;
  // $('.community-discount').hide();

  var admin_id = $('#community_admin_id').val();
  var manager_id = $('#community_manager_id').val();

  $('#community_admin_id option:not(:selected), #community_manager_id option:not(:selected)').remove();
  if($('#community_admin_id').val() !== '') $('#community_admin_id').prepend('<option value></option>');
  if($('#community_manager_id').val() !== '') $('#community_manager_id').prepend('<option value></option>');

  if((!company.users || !company.users.length) && (!window.community_people || !window.community_people.length)) {

    window.people.forEach(function(person) {
      window.setTimeout(function() {
        $('#community_admin_id, #community_manager_id').append('<option value="'+person.id+'">'+person.name+'</option>');
      }, 25);
    })

  }
  else {
    var people = {};

    if(window.community_people && window.community_people.length) {
      window.community_people.forEach(function(person) {
        people[person.id] = person.name;
      })

    }
    if(company.users && company.users.length) {
      company.users.forEach(function(person) {
        people[person.id] = person.name;
      })
    }
    console.log('keys', Object.keys(people));
    var keys = Object.keys(people).sort(function(first, second){
      if(people[first] && people[second]) {
        return people[first].localeCompare(people[second]);
      } else {
        return null;
      }
    })
    console.log('afterkeys', keys);
    keys.forEach(function(id) {
      if(id && id !== '') {
        window.setTimeout(function() {
          $('#community_admin_id, #community_manager_id').append('<option value="'+id+'">'+people[id]+'</option>');
        }, 25);
      }
    })

  }
  $('#community_admin_id').val(admin_id);
  $('#community_manager_id').val(manager_id);

 
}